import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { User as FirebaseUser, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, updateProfile } from 'firebase/auth';
import { ref, set, get, update } from 'firebase/database';
import { auth, database } from './firebaseConfig';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from './firebaseConfig';

interface GameData {
  date: string;
  winner: string;
  players: Array<{
    name: string;
    score: number;
    isFinalThrow: boolean;
    hasWon: boolean;
    throws: number;
    dice: Array<{
      value: number;
      isFrozen: boolean;
    }>;
  }>;
}

interface UserStats {
  gamesPlayed: number;
  gamesWon: number;
  highestScore: number;
  tripleFreezeThrows: number;
  lostAllPointsThrows: number;
  triplaTriplatThrows: number;
  triplatThrows: number;
  averageThrowsTo90: number; // New field
  breaks90Points: number; // New field
  winAfterBreaking90: number; // New field
  victoryDiceDistribution: { // New field for dice distribution
    twoDice: number;
    threeDice: number;
  };
}

interface User {
  uid: string;
  name: string;
  email: string;
  role: string;
  stats: UserStats;
}

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  signup: (name: string, email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  updateUserGameData: (gameData: GameData) => Promise<void>;
  updateSpecialThrowStats: (eventType: keyof UserStats) => Promise<void>;
  logPlayerAccuracyData: (diceValues: number[]) => Promise<void>;
  postEvent: (eventData: { title: string; description: string; date: string; imageFile?: File; imageUrl?: string; maxParticipants?: number }) => Promise<void>;  // Ensure postEvent type matches the actual function
  joinEvent: (eventId: string) => Promise<void>;

}

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  user: null,
  login: async () => {},
  signup: async () => {},
  logout: async () => {},
  updateUserGameData: async () => {},
  updateSpecialThrowStats: async () => {},
  logPlayerAccuracyData: async () => {},  
  postEvent: async () => {}, 
  joinEvent: async () => {},
  
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const isAuthenticated = !!user;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const userData = await fetchUserData(firebaseUser);
        setUser(userData);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserData = async (firebaseUser: FirebaseUser): Promise<User> => {
    const userRef = ref(database, `users/${firebaseUser.uid}`);
    const snapshot = await get(userRef);
  
    if (snapshot.exists()) {
      const data = snapshot.val();
      const stats = data.stats || {};
      return {
        uid: firebaseUser.uid,
        name: data.name,
        email: data.email,
        role: data.role || 'user',
        stats: {
          gamesPlayed: stats.gamesPlayed || 0,
          gamesWon: stats.gamesWon || 0,
          highestScore: stats.highestScore || 0,
          tripleFreezeThrows: stats.tripleFreezeThrows || 0,
          lostAllPointsThrows: stats.lostAllPointsThrows || 0,
          triplaTriplatThrows: stats.triplaTriplatThrows || 0,
          triplatThrows: stats.triplatThrows || 0,
          averageThrowsTo90: stats.averageThrowsTo90 || 0,
          breaks90Points: stats.breaks90Points || 0,
          winAfterBreaking90: stats.winAfterBreaking90 || 0,
          victoryDiceDistribution: {
            twoDice: stats.victoryDiceDistribution?.twoDice || 0,
            threeDice: stats.victoryDiceDistribution?.threeDice || 0,
          },
        },
      };
    } else {
      // Initialize new user with default stats
      const newUser: User = {
        uid: firebaseUser.uid,
        name: firebaseUser.displayName || 'Anonymous',
        email: firebaseUser.email || '',
        role: 'user',
        stats: {
          gamesPlayed: 0,
          gamesWon: 0,
          highestScore: 0,
          tripleFreezeThrows: 0,
          lostAllPointsThrows: 0,
          triplaTriplatThrows: 0,
          triplatThrows: 0,
          averageThrowsTo90: 0,
          breaks90Points: 0,
          winAfterBreaking90: 0,
          victoryDiceDistribution: {
            twoDice: 0,
            threeDice: 0,
          },
        },
      };
      await set(userRef, newUser);
      return newUser;
    }
  };
  

  const login = async (email: string, password: string) => {
    await signInWithEmailAndPassword(auth, email, password);
  };

  const signup = async (name: string, email: string, password: string) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    if (auth.currentUser) {
      await updateProfile(auth.currentUser, { displayName: name });
      const userRef = ref(database, `users/${userCredential.user.uid}`);
      const newUser: User = {
        uid: userCredential.user.uid,
        name,
        email,
        role: 'user',  // Default role for new users
        stats: {
          gamesPlayed: 0,
          gamesWon: 0,
          highestScore: 0,
          tripleFreezeThrows: 0,
          lostAllPointsThrows: 0,
          triplaTriplatThrows: 0,
          triplatThrows: 0,
          averageThrowsTo90: 0,
          breaks90Points: 0,
          winAfterBreaking90: 0,
          victoryDiceDistribution: {
            twoDice: 0,
            threeDice: 0,
          },
        },
      };
      await set(userRef, newUser);
      setUser(newUser);
    }
  };

  const logout = async () => {
    await signOut(auth);
    setUser(null);
  };

  const updateUserGameData = async (gameData: GameData) => {
    if (user) {
      const userRef = ref(database, `users/${user.uid}/stats`);
      const updatedUser = { ...user };
  
      updatedUser.stats.gamesPlayed += 1;
  
      // Explicitly type the player from gameData
      const userInGame = gameData.players.find((p): p is GameData['players'][0] => p.name === user.name);
  
      // Handle winning logic
      const didUserWin = gameData.winner === user.name;
      if (didUserWin && userInGame) {
        updatedUser.stats.gamesWon += 1;
  
        // Check if the player broke 90 points before winning
        if (userInGame.score >= 90) {
          updatedUser.stats.winAfterBreaking90 += 1;
        }
  
        // Update victoryDiceDistribution based on the winning dice values
        if (userInGame.dice) {
          // Count the non-zero dice in the current dice array
          const diceCount = userInGame.dice.filter(die => die.value > 0).length;
          
          // Increment the appropriate dice distribution
          if (diceCount === 2) {
            updatedUser.stats.victoryDiceDistribution.twoDice += 1;
          } else if (diceCount === 3) {
            updatedUser.stats.victoryDiceDistribution.threeDice += 1;
          }
        }
      }
  
      // Update other stats (e.g., breaks90Points, averageThrowsTo90)
      if (userInGame && userInGame.score >= 90) {
        updatedUser.stats.breaks90Points += 1;
  
        // Calculate average throws to 90
        const throwsTo90 = userInGame.throws || 0;
        updatedUser.stats.averageThrowsTo90 =
          (updatedUser.stats.averageThrowsTo90 * (updatedUser.stats.breaks90Points - 1) + throwsTo90) /
          updatedUser.stats.breaks90Points;
      }
  
      await update(userRef, updatedUser.stats);
      setUser(updatedUser);
    }
  };
  
  
  

  const updateSpecialThrowStats = async (eventType: keyof UserStats) => {
    if (user) {
      const userRef = ref(database, `users/${user.uid}/stats`);
  
      const updatedStats = { ...user.stats };
  
      if (eventType === 'victoryDiceDistribution') {
        console.error('Cannot increment victoryDiceDistribution directly.');
      } else if (typeof updatedStats[eventType] === 'number') {
        updatedStats[eventType] = (updatedStats[eventType] || 0) + 1;
      } else {
        console.error(`Invalid stat type for ${eventType}.`);
      }
  
      await update(userRef, updatedStats);
      setUser((prevUser) => (prevUser ? { ...prevUser, stats: updatedStats } : null));
    }
  };
  

  // Function to log accuracy data
  const logPlayerAccuracyData = async (diceValues: number[]) => {
    if (user) {
      const ENTRY_LIMIT = 100; // Adjust this number to your desired limit
      
      // Create the new turn data
      const newTurnData = {
        diceValues,
        hitMiss: diceValues.map((value) => value > 0),
        timestamp: new Date().toISOString(),
      };
  
      // Get reference to user's accuracy data
      const accuracyRef = ref(database, `users/${user.uid}/accuracyData`);
      
      // Get current entries
      const snapshot = await get(accuracyRef);
      const currentEntries = snapshot.val() || {};
      
      // Convert to array and sort by timestamp (newest first)
      const entriesArray = Object.entries(currentEntries)
        .sort(([, a]: [string, any], [, b]: [string, any]) => 
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        );
      
      // Keep only the most recent entries up to ENTRY_LIMIT - 1
      const limitedEntries = entriesArray.slice(0, ENTRY_LIMIT - 1);
      
      // Create new object with the new turn and limited old entries
      const updatedEntries = {
        [`turn${Date.now()}`]: newTurnData,
        ...Object.fromEntries(limitedEntries)
      };
  
      // Update the database with the limited dataset
      await set(accuracyRef, updatedEntries);
    }
  };

  // const logGameHistory = async (gameData: GameData) => {
  //   if (user) {
  //     const historyRef = ref(database, `users/${user.uid}/games/game${Date.now()}`);
  //     await set(historyRef, gameData);
  //   }
  // };
  

  const postEvent = async (eventData: { title: string; description: string; date: string; imageFile?: File; imageUrl?: string; maxParticipants?: number }) => {
    if (user && user.role === 'admin') {
      let imageUrl = eventData.imageUrl || '';
  
      try {
        // If an image file is provided, upload it to Firebase Storage
        if (eventData.imageFile) {
          const storageReference = storageRef(storage, `events/${Date.now()}_${eventData.imageFile.name}`);
          try {
            const snapshot = await uploadBytes(storageReference, eventData.imageFile);
            imageUrl = await getDownloadURL(snapshot.ref);
            console.log('Image uploaded successfully:', imageUrl);
          } catch (uploadError) {
            console.error('Error uploading image:', uploadError);
            throw new Error('Image upload failed');
          }
        }
  
        // Create a new event entry
        const eventRef = ref(database, `events/event${Date.now()}`); // Use a unique key for the event
  
        // Dynamically set the event data
        await set(eventRef, {
          title: eventData.title,
          description: eventData.description,
          date: eventData.date,
          imageUrl,  // Store the image URL in the event
          maxParticipants: eventData.maxParticipants || Infinity
        });
  
        alert('Event posted successfully');
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error('Error posting event:', error.message);
          alert(`Error posting event: ${error.message}`);
        } else {
          console.error('Unknown error:', error);
          alert('An unknown error occurred');
        }
      }
    } else {
      throw new Error('Unauthorized: Only admins can post events');
    }
  };

  const joinEvent = async (eventId: string) => {
    if (user) {
      try {
        // Get a reference to the event participants
        const eventRef = ref(database, `events/${eventId}/participants/${user.uid}`);
        const eventSnapshot = await get(ref(database, `events/${eventId}`));
    
        if (!eventSnapshot.exists()) {
          throw new Error('Event not found');
        }
  
        const eventData = eventSnapshot.val();
        const participantCount = eventData.participants ? Object.keys(eventData.participants).length : 0;
        const maxParticipants = eventData.maxParticipants || Infinity;
    
        // Check if the event has reached its max participant limit
        if (participantCount >= maxParticipants) {
          throw new Error('Event is full');
        }
    
        // Add the user to the participants list
        await set(eventRef, { name: user.name });
        alert('You have joined the event!');
      } catch (error) {
        console.error('Error joining event:', error);
        alert(`Error joining event: ${error instanceof Error ? error.message : 'Unknown error'}`);
      }
    } else {
      alert('You must be logged in to join an event');
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        signup,
        logout,
        updateUserGameData,
        updateSpecialThrowStats,
        logPlayerAccuracyData,  // Provide logPlayerAccuracyData function
        postEvent,
        joinEvent,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
