import React, { useState, useEffect, useCallback } from 'react';
import styled, { keyframes, css } from 'styled-components';
import Dice from './Dice';
import { FaTrophy, FaChevronDown } from 'react-icons/fa';
import { Player, PlayerHistory } from '../types';
import { applyDiceRules, calculateDiceSum } from '../gameLogic';
import { playSound } from '../Utils/soundUtil';

interface Props {
  player: Player;
  onDiceValueChange: (diceIndex: number, value: number) => void;
  endTurn: () => void;
  toggleFreeze: (diceIndex: number) => void;
  soundEnabled: boolean;
}

const Card = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isCurrent',
})<{ isCurrent: boolean }>`
  padding: ${({ theme }) => theme.spacing(2)};
  width: 90%;
  background-color: ${({ theme }) => theme.colors.surface};
  border: ${({ isCurrent, theme }) =>
    isCurrent ? `2px solid ${theme.colors.primary}` : `1px solid ${theme.colors.textSecondary}`};
  border-radius: 8px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  position: relative;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
`;

const NameAndStatus = styled.div`
  display: flex;
  align-items: center;
  h2 {
    font-size: 24px;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-family: 'Orbitron', sans-serif;  /* Match the fun casino style */
    letter-spacing: 1px;
    text-transform: uppercase;
  }
`;

const HostIndicator = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary};
  margin-left: ${({ theme }) => theme.spacing(0.5)};
`;

const StatusBadge = styled.span<{ $isFinalThrow: boolean; $hasWon: boolean }>`
  background-color: ${({ $isFinalThrow, $hasWon, theme }) =>
    $hasWon
      ? 'linear-gradient(45deg, #ff9800, #ffc107)'  /* Golden, flashy gradient for winners */
      : $isFinalThrow
      ? 'linear-gradient(45deg, #ff5722, #ff9800)'  /* Final throw is bright and warm */
      : 'transparent'};
  color: ${({ theme }) => theme.colors.textPrimary};
  padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing(1)};
  border-radius: 6px;
  font-size: 16px;  /* Slightly larger for visibility */
  margin-left: ${({ theme }) => theme.spacing(1)};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.5)};
  text-shadow: ${({ $hasWon }) =>
    $hasWon ? '0 0 10px #ffeb3b, 0 0 20px #ff9800' : 'none'};  /* Glow for winners */
`;

const ScoreText = styled.strong.withConfig({
  shouldForwardProp: (prop) => prop !== 'isCurrent' && prop !== 'animatePop',
})<{ isCurrent: boolean; animatePop: boolean }>`
  margin-top: ${({ theme }) => theme.spacing(1)}; 
  font-size: ${({ isCurrent }) => (isCurrent ? '32px' : '40px')};
  font-family: 'Orbitron', sans-serif;
  color: ${({ theme }) => theme.colors.primary};
  text-shadow: 0 0 10px ${({ theme }) => theme.colors.primary}, 0 0 20px ${({ theme }) => theme.colors.secondary};
  transition: transform 0.3s ease, color 0.3s ease;
  transform: ${({ isCurrent }) => (isCurrent ? 'scale(1.1)' : 'scale(1)')};
  ${({ animatePop }) =>
    animatePop &&
    css`
      animation: ${popShake} 0.5s ease-in-out;
    `}
`;

const popShake = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3) rotate(1deg);
  }
  60% {
    transform: scale(1.3) rotate(-1deg);
  }
  70% {
    transform: scale(1.2) rotate(1deg);
  }
`;

const DiceContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(1)};
`;

const EndTurnButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
  text-align: center;
`;

const EndTurnButton = styled.button`
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(3)};
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.textPrimary};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  box-shadow: 0 4px 14px rgba(255, 215, 0, 0.5);  /* Casino-like glowing button */
  transition: background-color 0.3s, transform 0.1s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    transform: scale(1.05);  /* Slight lift on hover */
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.textSecondary};
    cursor: not-allowed;
  }

  &:active {
    transform: scale(0.98);
  }
`;

const HistoryButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
  text-align: center;
`;

const HistoryButton = styled.button`
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.textPrimary};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    transform: scale(0.98);
  }
`;

const ArrowIcon = styled(FaChevronDown)`
  margin-left: ${({ theme }) => theme.spacing(0.5)};
`;

const HistoryContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${({ theme }) => theme.spacing(1)};
  border-radius: 4px;
  overflow: hidden;
`;

const HistoryItem = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

const FailureText = styled.span`
  color: red;
  font-weight: bold;
`;

const SpecialEventText = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
`;

// Helper function to animate score
const rollScore = (current: number, target: number): number => {
  const step = Math.sign(target - current) * Math.ceil(Math.abs(target - current) / 10); // Adjust the speed of the animation
  return current + step;
};

export type SoundKey =
| 'PISTE_1TO3'
| 'PISTE_4TO7'
| 'PISTE_8TO11'
| 'PISTE_12TO15'
| 'PISTE_16TO19'
| 'PISTE_20TO23'
| 'PISTE_24TO27'
| 'PISTE_28TO29'
| 'PISTE_30TO31';

const PlayerCard: React.FC<Props> = ({ player, onDiceValueChange, endTurn, toggleFreeze, soundEnabled  }) => {
  const [displayedScore, setDisplayedScore] = useState(player.score);
  const [showHistory, setShowHistory] = useState(false);
  const [animatePop, setAnimatePop] = useState(false);
  const [soundPlayed, setSoundPlayed] = useState(false); // Track if sound has been played for the current animation
  const [startingScore, setStartingScore] = useState(player.score); // Track the player's starting score for the turn


  
  // Helper function to determine which sound to play based on the turn score
  const getSoundKeyBasedOnTurnScore = (turnScore: number): SoundKey | null => {
    console.log(`Determining sound key for turn score: ${turnScore}`); // Debugging remove later
  
    if (turnScore >= 1 && turnScore <= 3) {
      return 'PISTE_1TO3'; 
    } else if (turnScore >= 4 && turnScore <= 7) {
      return 'PISTE_4TO7'; 
    } else if (turnScore >= 8 && turnScore <= 11) {
      return 'PISTE_8TO11'; 
    } else if (turnScore >= 12 && turnScore <= 15) {
      return 'PISTE_12TO15'; 
    } else if (turnScore >= 16 && turnScore <= 19) {
      return 'PISTE_16TO19'; 
    } else if (turnScore >= 20 && turnScore <= 23) {
      return 'PISTE_20TO23'; 
    } else if (turnScore >= 24 && turnScore <= 27) {
      return 'PISTE_24TO27'; 
    } else if (turnScore >= 28 && turnScore <= 29) {
      return 'PISTE_28TO29'; 
    } else if (turnScore >= 30 && turnScore <= 31) {
      return 'PISTE_30TO31'; 
    }
    return null;
  };

  // Function to handle playing the correct sound based on the player's total turn score
  const handleCoinSound = useCallback(
    (currentDisplayedScore: number) => {
      // If the current displayed score is different from the starting score, update the starting score
      if (currentDisplayedScore !== startingScore) {
        setStartingScore(currentDisplayedScore);
      }
  
      const turnScore = player.score - startingScore; // Calculate the player's total turn score
      console.log(`Total turn score: ${turnScore}`); // Debugging remove later
  
      // Handle edge case for scoring exactly 1 point
      if (turnScore === 1) {
        playSound('PISTE_1TO3');
        setSoundPlayed(true); // Mark sound as played
        console.log('Playing isolated sound for scoring exactly 1 point');
        return;
      }
  
      const soundKey = getSoundKeyBasedOnTurnScore(turnScore);
  
      // If a valid sound key is found and the sound hasn't been played, play the sound
      if (soundKey && !soundPlayed) {
        playSound(soundKey);
        setSoundPlayed(true); // Mark sound as played
        console.log(`Playing sound for total turn score: ${soundKey}`);
      }
    },
    [startingScore, player.score, soundPlayed] // Dependencies for handleCoinSound
  );

  useEffect(() => {
    const targetScore = Math.min(player.score, 90);

    if (displayedScore !== targetScore) {
      // Reset the soundPlayed state when a new turn starts
      setSoundPlayed(false);

      // Handle special case where the player scores exactly 1 point with a separate animation
      if (targetScore - displayedScore === 1) {
        const interval = setInterval(() => {
          setDisplayedScore((prevScore) => {
            const newScore = prevScore + 1; // Increment slowly for 1-point score

            if (newScore === targetScore) {
              clearInterval(interval);
              handleCoinSound(newScore); // Play the sound specifically for 1-point increments
            }
            return newScore;
          });
        }, 150); // Slower interval for visual emphasis on the single point increment

        return () => clearInterval(interval);
      }

      // Normal interval for other scores
      const interval = setInterval(() => {
        setDisplayedScore((prevScore) => {
          const newScore = rollScore(prevScore, targetScore); // Cap the score at 90

          if (newScore === targetScore) {
            clearInterval(interval);
            handleCoinSound(newScore); // Pass the newScore to handleCoinSound for sound handling
          }
          return newScore;
        });
      }, 50); // Adjust speed by changing the interval timing

      // Cleanup the interval when effect dependencies change or component unmounts
      return () => clearInterval(interval);
    }
  }, [player.score, displayedScore, handleCoinSound]);


  useEffect(() => {
    // Trigger the pop animation and sound when the score reaches 90
    if (displayedScore === 90) {
      setAnimatePop(true);
      if (soundEnabled) {
        console.log("Playing Rikki sound");
        playSound('Rikki'); // Play the Rikki sound when the score reaches 90
      }
      // Reset the animation state after it's finished
      setTimeout(() => setAnimatePop(false), 500);
    }
  }, [displayedScore, soundEnabled]);

  const toggleHistory = () => {
    setShowHistory(!showHistory);
  };

  const getSpecialEventMessage = (entry: PlayerHistory) => {
    const { diceValues, isFrozen } = entry;
    const { score, outcome } = applyDiceRules(
      diceValues.map((value, index) => ({
        value,
        isFrozen: isFrozen[index],
      }))
    );
  
    // Miss condition
    if (outcome === 'miss') {
      return <FailureText>Miss!</FailureText>;
    }
  
    // Lost all points condition
    if (outcome === 'lose') {
      return <FailureText> (Lost all points)</FailureText>;
    }
  
    // Freeze condition
    if (outcome === 'freeze') {
      // Check if any points were doubled (this happens if frozen die has value 6 and 2 non-frozen dice)
      const frozenSix = diceValues.some((value, index) => isFrozen[index] && value === 6);
      const nonFrozenDiceCount = diceValues.filter((value, index) => !isFrozen[index] && value > 0).length;
  
      // Display different messages based on whether points were doubled or not
      if (frozenSix && nonFrozenDiceCount === 2) {
        return <SpecialEventText> (Freeze! - Frozen dice doubled)</SpecialEventText>;
      } else {
        return <SpecialEventText> (Freeze! - No points doubled)</SpecialEventText>; // No points doubled case
      }
    }
  
    // Tripla-Triplat condition
    if (score === 90 && outcome === 'continue') {
      return <SpecialEventText> (Tripla-Triplat!)</SpecialEventText>;
    }
  
    // Automatic Win condition (6,6,6)
    if (outcome === 'win') {
      return <SpecialEventText> (Automatic Win with 6,6,6!)</SpecialEventText>;
    }
  
    // Triplat condition (score doubled)
    if (score === calculateDiceSum(diceValues.map((value) => ({ value, isFrozen: false }))) * 2) {
      return <SpecialEventText> (Triplat!)</SpecialEventText>;
    }
  
    return null;
  };
  

  const renderHistoryContent = (entry: PlayerHistory, isFinalThrow: boolean) => {
    const specialMessage = getSpecialEventMessage(entry);
  
    if (specialMessage && specialMessage.type === FailureText) {
      return <>{specialMessage}</>;
    }
  
    return (
      <>
        Dice: [{entry.diceValues.join(', ')}], Turn Score: {entry.turnScore} {isFinalThrow && <strong>(FinalThrow)</strong>} {specialMessage}
      </>
    );
  };
  
  return (
    <Card isCurrent={player.isCurrent}>
      <Header>
        <NameAndStatus>
          <h2>
            {player.name}
            {player.isHost && <HostIndicator>(Host)</HostIndicator>}
          </h2>
          {player.isFinalThrow && !player.hasWon && (
            <StatusBadge $isFinalThrow={true} $hasWon={false}>
              <FaTrophy />
              Final Throw
            </StatusBadge>
          )}
          {player.hasWon && (
            <StatusBadge $isFinalThrow={false} $hasWon={true}>
              <FaTrophy />
              Winner!
            </StatusBadge>
          )}
        </NameAndStatus>
        {/* Use the displayed score here */}
        <ScoreText isCurrent={player.isCurrent} animatePop={animatePop} >Score: {displayedScore}</ScoreText>
      </Header>
  
      {player.isCurrent && (
        <>
          <DiceContainer>
            {player.dice.map((die, index) => (
              <Dice
                key={index}
                value={die.value}
                isFrozen={die.isFrozen}
                onToggleFreeze={() => toggleFreeze(index)}
                onChange={(value) => onDiceValueChange(index, value)}
                soundEnabled={soundEnabled}
              />
            ))}
          </DiceContainer>
          <EndTurnButtonContainer>
            <EndTurnButton onClick={endTurn}>End Turn</EndTurnButton>
          </EndTurnButtonContainer>
        </>
      )}
  
      <HistoryButtonContainer>
        <HistoryButton onClick={toggleHistory}>
          {showHistory ? 'Hide History' : 'Show History'}
          <ArrowIcon rotate={showHistory ? 180 : 0} />
        </HistoryButton>
      </HistoryButtonContainer>
  
      {showHistory && (
        <HistoryContainer>
          <h4>History</h4>
          {player.history.length === 0 ? (
            <p>No history yet.</p>
          ) : (
            player.history.map((entry, index) => (
              <HistoryItem key={index}>
                <p>
                  <strong>Turn {index + 1}:</strong> {renderHistoryContent(entry, !!entry.isFinalThrowAttempt)}
                </p>
              </HistoryItem>
            ))
          )}
        </HistoryContainer>
      )}
    </Card>
  );
  };
  
  export default PlayerCard;
  