import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Cover from '../media/TripleFreezerCover2.jpg';
import Logo from '../media/Dice_logo_finished-1.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background: url(${Cover}) no-repeat center center;
  background-size: cover;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333333;
  padding: ${({ theme }) => theme.spacing(2)};
  border-radius: 12px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;
  text-align: center;
`;

const LogoImage = styled.img`
  width: 150px;
  height: auto;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

const Title = styled.h1`
  font-size: 24px;
  color: #f5f5f5;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Button = styled.button`
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(3)};
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.textPrimary};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  width: 100%;
  max-width: 200px;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }

  &:active {
    transform: scale(0.98);
  }
`;

const Home: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <ContentWrapper>
        <LogoImage src={Logo} alt="Game Logo" />
        <Title>Welcome to Triple Freezer</Title>
        <Button onClick={() => navigate('/lobby')}>Go to Game Lobby</Button>
        <Button onClick={() => navigate('/Buy')}>Buy The Game</Button>
        <Button onClick={() => navigate('/Events')}>View Events</Button>
        <Button onClick={() => navigate('/where-to-play')}>Where To Play</Button>
      </ContentWrapper>
    </Container>
  );
};

export default Home;
