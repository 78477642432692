import React, { useState, useContext, useEffect } from 'react';
import styled, { ThemeContext, DefaultTheme } from 'styled-components';
import { motion } from 'framer-motion';
import { lightTheme, darkTheme } from '../styles/themes';

const SwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

const SwitchLabel = styled.span`
  font-size: 16px;
  margin-right: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const Switch = styled(motion.div)`
  width: 50px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.primaryDark};
  border-radius: 12px;
  position: relative;
  cursor: pointer;
`;

const SwitchToggle = styled(motion.div)`
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
`;

const ThemeSwitcher: React.FC = () => {
  const themeContext = useContext(ThemeContext) as DefaultTheme;
  
  // Initialize state from localStorage, falling back to system preference
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      return savedTheme === 'dark';
    }
    // If no saved preference, use system preference
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  // Effect to sync theme changes with localStorage
  useEffect(() => {
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
    themeContext.setTheme(isDarkMode ? darkTheme : lightTheme);
  }, [isDarkMode, themeContext]);

  // Effect to listen for system theme changes
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e: MediaQueryListEvent) => {
      if (!localStorage.getItem('theme')) {
        setIsDarkMode(e.matches);
      }
    };

    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <SwitchContainer>
      <SwitchLabel>{isDarkMode ? 'Dark' : 'Light'} Mode</SwitchLabel>
      <Switch onClick={toggleTheme}>
        <SwitchToggle
          animate={{ x: isDarkMode ? 26 : 0 }}
          transition={{ type: 'spring', stiffness: 300 }}
        />
      </Switch>
    </SwitchContainer>
  );
};

export default ThemeSwitcher;