import React, { useState } from 'react';
import styled from 'styled-components';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const places = [
  { id: 1, name: 'Onda - Music & Arts Cafe', lat: 61.49865, lng: 23.76825, address: 'Aleksanterinkatu 22, Tampere' },
  //{ id: 2, name: 'Westside Gaming Lounge', lat: 40.741895, lng: -73.989308, address: '456 Elm St, NY' },
  //{ id: 3, name: 'East End Gamers Club', lat: 40.712776, lng: -74.005974, address: '789 Oak Ave, NY' },
];

const customIcon = new L.Icon({
  iconUrl: 'https://cdn-icons-png.flaticon.com/512/854/854878.png',
  iconSize: [32, 32],
});

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
`;

const ListContainer = styled.div`
  width: 30%;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.background};
  overflow-y: auto;
`;

const PlaceItem = styled.div`
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.surface};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`;

const MapWrapper = styled.div`
  width: 70%;
  height: 100%;
`;

interface Place {
  id: number;
  name: string;
  lat: number;
  lng: number;
  address: string;
}

// Separate component for map controls
function MapController({ selectedPlace }: { selectedPlace: Place | null }) {
  const map = useMap();
  
  React.useEffect(() => {
    if (selectedPlace) {
      map.flyTo([selectedPlace.lat, selectedPlace.lng], 18);  // zoom level on click 
    }
  }, [selectedPlace, map]);

  return null;
}

const WhereToPlay: React.FC = () => {
  const [selectedPlace, setSelectedPlace] = useState<Place | null>(null);

  const handlePlaceClick = (place: Place) => {
    setSelectedPlace(place);
  };

  return (
    <Container>
      <ListContainer>
        <h2>Where to Play</h2>
        {places.map((place) => (
          <PlaceItem key={place.id} onClick={() => handlePlaceClick(place)}>
            <h3>{place.name}</h3>
            <p>{place.address}</p>
          </PlaceItem>
        ))}
      </ListContainer>

      <MapWrapper>
        <MapContainer center={[61.49865, 23.76825]} zoom={13} style={{ height: '100%', width: '100%' }}> 
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <MapController selectedPlace={selectedPlace} />
          {places.map((place) => (
            <Marker key={place.id} position={[place.lat, place.lng]} icon={customIcon}>
              <Popup>
                <strong>{place.name}</strong>
                <br />
                {place.address}
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </MapWrapper>
    </Container>
  );
};

export default WhereToPlay;