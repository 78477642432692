import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Home from './components/Home';
import WhereToPlay from './components/WhereToPlay';
import StartScreen from './components/StartScreen';
import PlayerCard from './components/PlayerCard';
import WinnerScreen from './components/WinnerScreen';
import NavBar from './components/NavBar';
import Rules from './components/Rules';
import BuyGame from './components/BuyGame';
import Events from './components/Events';
import Login from './components/Login';
import Signup from './components/Signup';
import Profile from './components/Profile';
import AdminPage from './components/AdminPage';
import SpecialMessagePopup from './components/SpecialMessagePopup'; 
import ConfirmationModal from './components/ConfirmationModal';
import { resumeAudioContext } from "./Utils/soundUtil";
import {
   logGamePlayedEvent,
   logGameFinishedEvent,
   logDiceCalculatedEvent,
   logTripleFreezeEvent,
   logLostAllPointsEvent,
   logTriplaTriplatEvent,
   logTriplatEvent } from './firebaseConfig';
import { AuthContext } from './AuthContext';
import { checkForFinalThrow, checkWinningThrow, applyDiceRules } from './gameLogic';
import { Player, PlayerHistory } from './types';
import { handleTurnSounds } from './Utils/soundHandler';


const AppContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  overflow: hidden;
  
`;

const buttonStyle = {
  padding: '10px 20px',
  backgroundColor: '#007BFF',
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  margin: '0 10px',
};

const App: React.FC = () => {
  const [players, setPlayers] = useState<Player[]>([]);
  const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);
  const [gameWon, setGameWon] = useState(false);
  const [specialMessage, setSpecialMessage] = useState<string | null>(null);
  const { isAuthenticated, user, updateUserGameData, updateSpecialThrowStats, logPlayerAccuracyData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showUndoModal, setShowUndoModal] = useState(false);
  const [isGameRestored, setIsGameRestored] = useState(false);  // New state to track if game is restored
  const [soundEnabled, setSoundEnabled] = useState(true);  // New state for sound toggle


  // Load the saved state from localStorage only on page refresh (not on route change)
  useEffect(() => {
    const savedGameState = localStorage.getItem('gameState');
  
    // Check if a game state exists in localStorage
    if (savedGameState) {
      const parsedGameState = JSON.parse(savedGameState);
      
      // Restore game state
      setPlayers(parsedGameState.players);
      setCurrentPlayerIndex(parsedGameState.currentPlayerIndex);
      setGameWon(parsedGameState.gameWon);
  
      // Only navigate to '/game' if the user is not on another route
      if (window.location.pathname === '/' && !parsedGameState.gameWon) {
        navigate('/game');  // Navigate to the game if it's still in progress
      }
      setIsGameRestored(true);
    }
  }, [navigate]);

  // Save the game state to localStorage whenever it changes
  useEffect(() => {
    if (isGameRestored) {
      const gameState = {
        players,
        currentPlayerIndex,
        gameWon,
      };
      localStorage.setItem('gameState', JSON.stringify(gameState));
    }
  }, [players, currentPlayerIndex, gameWon, isGameRestored]);

  // Clear localStorage when the game ends, or the player navigates away
  const clearGameState = () => {
    localStorage.removeItem('gameState');
    setIsGameRestored(false); // Reset the restoration flag
  };
  

    // Function to handle undo button click, shows modal
    const handleUndoClick = () => {
      setShowUndoModal(true);
    };
  
    // Function to confirm the undo action
    const confirmUndo = () => {
      setShowUndoModal(false); // Close modal
      undoLastTurn(); // Perform the undo action
    };
  
    // Function to cancel the undo action
    const cancelUndo = () => {
      setShowUndoModal(false); // Close modal
    };

  // Function to undo the last turn
  const undoLastTurn = () => {
    setPlayers((prevPlayers) => {
      const updatedPlayers = [...prevPlayers];
  
      // If there's only one player, handle undo for the same player
      if (updatedPlayers.length === 1) {
        const currentPlayer = updatedPlayers[0];
  
        // If there is no history to undo, return early
        if (currentPlayer.history.length === 0) {
          return prevPlayers;
        }
  
        // Get the last history entry and remove it
        const lastHistoryEntry = currentPlayer.history.pop();
  
        // Subtract the score from the last turn (use the turn-specific score)
        const lastTurnScore = lastHistoryEntry ? lastHistoryEntry.turnScore : 0;
        const updatedScore = currentPlayer.score - lastTurnScore;
  
        // Update the current player's score and reset their dice
        updatedPlayers[0] = {
          ...currentPlayer,
          score: Math.max(updatedScore, 0), // Ensure score doesn't go below 0
          history: currentPlayer.history,
          dice: currentPlayer.dice.map((die) => ({ ...die, value: 0, isFrozen: false })), // Reset dice
          isCurrent: true, // Keep the current player active
        };
  
        return updatedPlayers;
      }
  
      // Multi-player case: Get the previous player's index
      const previousPlayerIndex = (currentPlayerIndex - 1 + updatedPlayers.length) % updatedPlayers.length;
      const previousPlayer = updatedPlayers[previousPlayerIndex];
      const currentPlayer = updatedPlayers[currentPlayerIndex];
  
      // If there is no history to undo for the previous player, return early
      if (previousPlayer.history.length === 0) {
        return prevPlayers;
      }
  
      // Get the last history entry and remove it
      const lastHistoryEntry = previousPlayer.history.pop();
  
      // Subtract the score from the last turn (use the turn-specific score instead of totalScore)
      const lastTurnScore = lastHistoryEntry ? lastHistoryEntry.turnScore : 0;
      const updatedScore = previousPlayer.score - lastTurnScore;
  
      // Update the previous player's score and reset their dice
      updatedPlayers[previousPlayerIndex] = {
        ...previousPlayer,
        score: Math.max(updatedScore, 0), // Ensure score doesn't go below 0
        history: previousPlayer.history,
        dice: previousPlayer.dice.map((die) => ({ ...die, value: 0, isFrozen: false })), // Reset dice
        isCurrent: true, // Make the previous player active
      };
  
      // Set the current player (who's card is open) back to inactive (minimized)
      updatedPlayers[currentPlayerIndex] = {
        ...currentPlayer,
        isCurrent: false, // Minimize the current player’s card
      };
  
      // Set the current player back to the previous player
      setCurrentPlayerIndex(previousPlayerIndex);
  
      return updatedPlayers;
    });
    saveGameStateToLocalStorage();
  };

    // Function to save the game state to localStorage
    const saveGameStateToLocalStorage = () => {
      const gameState = {
        players,
        currentPlayerIndex,
        gameWon,
      };
      localStorage.setItem('gameState', JSON.stringify(gameState));
    };


  const startGame = (playerList: { id: string; name: string }[]) => {
    const initializedPlayers = playerList.map((player, index) => {
      const isHost = isAuthenticated && user ? player.name === user.name : false;

      return {
        ...player,
        dice: [
          { value: 0, isFrozen: false },
          { value: 0, isFrozen: false },
          { value: 0, isFrozen: false },
        ],
        isCurrent: index === 0,
        score: 0,
        lastAnnouncedScore: 0,
        history: [],
        isFinalThrow: false,
        hasWon: false,
        isHost,
        throws: 0,
      } as Player;
    });
    setPlayers(initializedPlayers);
    setCurrentPlayerIndex(0);
    setGameWon(false);
    logGamePlayedEvent('Triple Freezer');
    clearGameState();
    navigate('/game');
  };

  const handleDiceValueChange = (diceIndex: number, value: number) => {
    if (value < 0) value = 0;
    if (value > 6) value = 6;
    setPlayers((prevPlayers) =>
      prevPlayers.map((player, index) =>
        index === currentPlayerIndex
          ? {
              ...player,
              dice: player.dice.map((die, idx) =>
                idx === diceIndex ? { ...die, value } : die
              ),
            }
          : player
      )
    );
    logDiceCalculatedEvent('Triple Freezer', value);
    saveGameStateToLocalStorage();
  };

  // toggleFreeze function
  const toggleFreeze = (diceIndex: number) => {
    setPlayers((prevPlayers) =>
      prevPlayers.map((player, index) =>
        index === currentPlayerIndex
          ? {
              ...player,
              dice: player.dice.map((die, idx) =>
                idx === diceIndex ? { ...die, isFrozen: !die.isFrozen } : die
              ),
            }
          : player
      )
    );
    saveGameStateToLocalStorage();
  };

  const handleSpecialMessage = (message: string) => {
    setSpecialMessage(message);
  };

  const handleTurn = (score: number, currentPlayer: any, outcome: string, allDiceFrozenWithSix: boolean) => {
    handleTurnSounds(score, currentPlayer, outcome, allDiceFrozenWithSix, soundEnabled);
  };

  const endTurn = () => {
    const currentPlayer = players[currentPlayerIndex];
    const { score, outcome } = applyDiceRules(currentPlayer.dice);
  
    const diceValues = currentPlayer.dice.map(die => die.value);  // Capture dice values

    // Increment throws for the current player
    currentPlayer.throws = (currentPlayer.throws || 0) + 1;
  
    // Log accuracy data after dice values are determined
    if (isAuthenticated && user) {
      logPlayerAccuracyData(diceValues);
    }
  
    const allDiceFrozenWithSix = currentPlayer.dice.every(die => die.isFrozen && die.value === 6);
  
    // Call handleTurn to handle sounds and other related logic
    handleTurn(score, currentPlayer, outcome, allDiceFrozenWithSix);
  
    let specialEventMessage = null;
    if (allDiceFrozenWithSix) {
      specialEventMessage = 'Triple Freeze!';
      logTripleFreezeEvent(currentPlayer.name);
      if (isAuthenticated && user) updateSpecialThrowStats('tripleFreezeThrows');
    } else if (outcome === 'win') {
      specialEventMessage = 'Insta Win!';
      logTripleFreezeEvent(currentPlayer.name);
      if (isAuthenticated && user) updateSpecialThrowStats('tripleFreezeThrows'); // Update to log insta win
    } else if (outcome === 'lose') {
      specialEventMessage = 'Lost All Points with 1,1,1';
      logLostAllPointsEvent(currentPlayer.name);
      if (isAuthenticated && user) updateSpecialThrowStats('lostAllPointsThrows');
    } else if (score === 90) {
      specialEventMessage = 'Tripla-Triplat!';
      logTriplaTriplatEvent(currentPlayer.name);
      if (isAuthenticated && user) updateSpecialThrowStats('triplaTriplatThrows');
    } else if (score === 0) {
      specialEventMessage = 'Miss!';
    } else if (outcome === 'freeze') {
      specialEventMessage = 'Freeze!';
    } else if (currentPlayer.dice.every((die) => die.value > 0)) {
      specialEventMessage = 'Triplat!';
      logTriplatEvent(currentPlayer.name);
      if (isAuthenticated && user) updateSpecialThrowStats('triplatThrows');
    }
  
    if (specialEventMessage) {
      handleSpecialMessage(specialEventMessage);
    } else {
      proceedWithEndTurn(score);
    }
  
    saveGameStateToLocalStorage();
    
  };



  const proceedWithEndTurn = (turnScore: number = 0) => {
    const currentPlayer = players[currentPlayerIndex];
    const { score, outcome } = applyDiceRules(currentPlayer.dice);
    let hasGameWon = false;
  
    const updatedPlayers = players.map((player, index) => {
      if (index === currentPlayerIndex) {
        let updatedScore = player.score + score;
  
        // Cap the updated score at 90
        if (updatedScore > 90) {
          updatedScore = 90;
        }
  
        let isFinalThrow = player.isFinalThrow;
        let hasWon = player.hasWon;
  
        let newHistoryEntry: PlayerHistory = {
          diceValues: player.dice.map((die) => die.value),
          isFrozen: player.dice.map((die) => die.isFrozen),
          turnScore: Math.min(score, 90 - player.score), // Ensure turn score doesn't exceed remaining points to reach 90
          isFinalThrowAttempt: isFinalThrow // Track final throw attempts
        };
  
        if (!player.isFinalThrow) {
          newHistoryEntry.turnScore = Math.min(score, 90 - player.score); // Only store the valid turn score
  
          if (outcome === 'win') {
            hasWon = true;
            hasGameWon = true;
            newHistoryEntry.hasWonAttempt = true;
          } else if (outcome === 'lose') {
            updatedScore = 0;
            newHistoryEntry.turnScore = 0;
          } else if (outcome === 'continue' && checkForFinalThrow(updatedScore)) {
            isFinalThrow = true;
          }
        } else {
          newHistoryEntry.isFinalThrowAttempt = true;
  
          if (checkWinningThrow(score)) {
            hasWon = true;
            hasGameWon = true;
            newHistoryEntry.hasWonAttempt = true;
          } else {
            newHistoryEntry.hasWonAttempt = false;
          }
        }
  
        return {
          ...player,
          score: updatedScore,  // Ensure score does not exceed 90
          history: [...player.history, newHistoryEntry],
          dice: player.dice.map((die) => ({ ...die, value: 0, isFrozen: false })),
          isFinalThrow,
          hasWon,
        };
      }
      return player;
    });
  
    setPlayers(updatedPlayers);
  
    if (hasGameWon) {
      setGameWon(true);
      logGameFinishedEvent('Triple Freezer', currentPlayer.name, currentPlayer.score);
      if (isAuthenticated && user) {
        const winner = updatedPlayers.find((p) => p.hasWon);
        const gameData = {
          date: new Date().toISOString(),
          winner: winner?.name || '',
          players: updatedPlayers.map((p) => ({
            name: p.name,
            score: p.score,
            isFinalThrow: p.isFinalThrow,
            hasWon: p.hasWon,
            throws: p.throws,
            dice: p.dice,
            history: p.history
          })),
        };
  
        const isUserInGame = updatedPlayers.some((p) => p.name === user.name);
  
        if (isUserInGame) {
          updateUserGameData(gameData);
        }
      }
    } else {
      nextTurn();
    }
    saveGameStateToLocalStorage();
  };
  
  

  const nextTurn = () => {
    setCurrentPlayerIndex((prevIndex) => (prevIndex + 1) % players.length);
    setPlayers((prevPlayers) =>
      prevPlayers.map((player, index) => ({
        ...player,
        isCurrent: index === (currentPlayerIndex + 1) % prevPlayers.length,
      }))
    );
    saveGameStateToLocalStorage();
  };

  const dismissSpecialMessage = () => {
    setSpecialMessage(null);
    proceedWithEndTurn();
    saveGameStateToLocalStorage();
  };

  const handleQuitGame = () => {
    clearGameState(); // Clear the saved game state
    setPlayers([]); // Reset players
    setGameWon(false); // Reset game won state
    navigate('/'); // Navigate back to start screen
  };

  const handleRestartGame = () => {
    // Restart the game with the same players
    const newPlayers = players.map(player => ({
      ...player,
      score: 0,
      dice: player.dice.map(die => ({ value: 0, isFrozen: false })),
      history: [],
      hasWon: false,
      isFinalThrow: false,
    }));

    setPlayers(newPlayers);
    setCurrentPlayerIndex(0);
    setGameWon(false);
    clearGameState(); // Optionally clear game state from localStorage if you want a fresh start
    saveGameStateToLocalStorage(); // Save the reset game state
    navigate('/game'); // Redirect back to the game screen
  };

  useEffect(() => {
    // Define a function to resume the audio context on interaction
    const handleUserInteraction = () => {
      resumeAudioContext();
    };
  
    // Attach event listeners for user interactions
    document.addEventListener("click", handleUserInteraction);
    document.addEventListener("keydown", handleUserInteraction);
    document.addEventListener("touchstart", handleUserInteraction);
  
    // Clean up the event listeners when the component unmounts
    return () => {
      document.removeEventListener("click", handleUserInteraction);
      document.removeEventListener("keydown", handleUserInteraction);
      document.removeEventListener("touchstart", handleUserInteraction);
    };
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts
  

  return (
    <>
      <NavBar players={players} soundEnabled={soundEnabled} setSoundEnabled={setSoundEnabled} />
      <AppContainer>
        {specialMessage && (
          <SpecialMessagePopup message={specialMessage} onDismiss={dismissSpecialMessage} />
        )}
  
        {showUndoModal && (
          <ConfirmationModal
            onConfirm={confirmUndo}  // Call confirmUndo when confirmed
            onCancel={cancelUndo}    // Call cancelUndo when canceled
          />
        )}
  
        <Routes>

          {/* Home Screen */}
          <Route path="/" element={<Home />} />

          {/* Start Screen */}
          <Route path="/lobby" element={<StartScreen onStartGame={startGame} />} />
          
          {/* Game Screen */}
          <Route
            path="/game"
            element={
              players.length > 0 ? (   // Check if the game has players (game in progress)
                gameWon ? (
                  <WinnerScreen
                    winnerName={players.find((p) => p.hasWon)?.name}
                    players={players}
                    clearGameState={clearGameState}
                    startGame={startGame} 
                  />
                ) : (
                  <>
                    {/* Game is ongoing */}
                    <div style={{ textAlign: 'center', margin: '50px 0' }}>
                      <button
                        onClick={handleUndoClick} // Show confirmation modal on click
                        disabled={players.every(player => player.history.length === 0)} // Disable if no history to undo
                        style={buttonStyle}
                      >
                        Undo Last Turn
                      </button>
  
                      <button
                        onClick={handleRestartGame} // Restart the game with the same players
                        style={{ ...buttonStyle, backgroundColor: '#28a745' }} // Green button for restart
                      >
                        Restart Game
                      </button>
  
                      <button
                        onClick={handleQuitGame} // Quit the game and navigate to start
                        style={{ ...buttonStyle, backgroundColor: '#dc3545' }} // Red button for quit
                      >
                        Quit Game
                      </button>
                    </div>
  
                    {/* Render Player Cards */}
                    {players.map((player) => (
                      <PlayerCard
                        key={player.id}
                        player={player}
                        onDiceValueChange={handleDiceValueChange}
                        endTurn={endTurn}
                        toggleFreeze={toggleFreeze}
                        soundEnabled={soundEnabled}  // Pass soundEnabled to PlayerCard
                      />
                    ))}
                  </>
                )
              ) : (
                <Navigate to="/" replace />   // If no game is in progress, redirect to home
              )
            }
          />
          
          {/* Other Pages */}
          <Route path="/rules" element={<Rules />} />
          <Route path="/buy" element={<BuyGame />} />
          <Route path="/events" element={<Events />} />
          <Route path="/where-to-play" element={<WhereToPlay />} />

  
          {/* Protected Admin Page */}
          <Route
            path="/admin"
            element={
              isAuthenticated && user?.role === 'admin' ? (
                <AdminPage />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
  
          {/* Auth Routes */}
          <Route
            path="/login"
            element={isAuthenticated ? <Navigate to="/profile" replace /> : <Login />}
          />
          <Route
            path="/profile"
            element={isAuthenticated ? <Profile /> : <Navigate to="/login" replace />}
          />
          <Route
            path="/signup"
            element={isAuthenticated ? <Navigate to="/profile" replace /> : <Signup />}
          />
        </Routes>
      </AppContainer>
    </>
  );
}

export default App;